import * as React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import media from 'styled-media-query';
import { motion } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import RevealWord from '../components/RevealWord';
import { Page } from '../components';

const Section = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
`
const Title = styled.h1`
  font-size: 80px;
  font-family: 'Krona One', sans-serif;
  color: white;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 80px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;

   div {
     text-align: center;
   }
`

const Subtitle = styled(motion.h2)`
  font-size: 16px;
  font-family: 'Krona One', sans-serif;
  color: white;
  text-transform: uppercase;
  margin-bottom: 64px;
`

const Cta = styled(motion.span)`
  font-size: 12px;
  text-transform: lowercase;
  font-weight: 400;
  letter-spacing: 2px;
  cursor: pointer;
  position: relative;

  :hover {
    :after {
      width: 100%;
    }
  }

  :after {
    content: '';
    width: 50%;
    height: 1px;
    position: absolute;
    top: calc(100% + 10px);
    transition: all 800ms ease;
    left: 0;
    background: #8effac;
  }

  a {
    text-decoration: none;
  }
`;

const BackLetters = styled.div`
  display: flex;
  flex-flow: column wrap;
  color: black;
  font-size: 18vh;
  font-family: 'Krona One', sans-serif;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  margin: auto;

  span {
    color: black;
  }
`

const BackgroundImage = styled(motion.div)`
  width: 100%;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  user-select: none;
`;

const Content = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
`

const NotFoundPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "img404.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  const imageData = data.background.childImageSharp.fluid;

  return (
    <Page title="Página não encontrada" description="Página cases do site Lab721">
      <BackgroundImage
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { delay: 0.5, bounce: 0, duration: 2 },
        }}
      >
        <Img fluid={imageData} className="background-image" />
      </BackgroundImage>

      <Section id="404">
        <BackLetters>
          <motion.span initial={{ opacity: 0, x: 25 }} animate={{ opacity: 1, x: 0, transition: { type: 'tween', duration: 6, bounce: 0.2, delay: 0.2 } }}>4</motion.span>
          <motion.span initial={{ opacity: 0, x: -25 }} animate={{ opacity: 1, x: 0, transition: { type: 'tween', duration: 6, bounce: 0.2, delay: 0.25 } }}>0</motion.span>
          <motion.span initial={{ opacity: 0, x: 25 }} animate={{ opacity: 1, x: 0, transition: { type: 'tween', duration: 6, bounce: 0.2, delay: 0.3 } }}>4</motion.span>
        </BackLetters>
        <Content>
        <Title>
          <RevealWord delay={1.2}>Zona</RevealWord>
          <RevealWord delay={1.4}>Perigosa</RevealWord>
        </Title>
        <Subtitle
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0, transition: {delay: 1.7} }}
          transition={{
            type: 'spring',
            damping: 10,
            stiffness: 20,
          }}
        >
          Parece que você está perdido
        </Subtitle>

        <Cta
            initial={{ opacity: 0, y: 10 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: 2, type: 'spring', bounce: 0.2 },
            }}
            whileHover={{ 'letter-spacing': '3px', x: -10 }}
            transition={{
              type: 'spring',
              damping: 10,
              stiffness: 20,
            }}
          >
            <Link to="/">Leve-me para a segurança</Link>
          </Cta>
          </Content>
      </Section>
    </Page>
  )
}

export default NotFoundPage
